


























































import { Component, Vue } from 'vue-property-decorator';
import hexabaseState from '@/store/hexabase';
import searchState from '@/store/search';
import Setting from '@/models/settingModel';
import { FieldChildrenProp, GetItemsResponse } from '@/services/hexabase/types';
import { searchFormEmployeeList } from '@/constants/form/settingEmployeeForm';
import {
  EmployeeListDefine,
  EmployeeMasterDeleteStatusExcludeSearch
} from '@/constants/setting/employee';
import { MemberList } from '@/constants/setting/types';

import Header from '@/components/common/modules/Header.vue';
import SideMenu from '@/components/common/modules/SideMenu.vue';
import PageInfoBar from '@/components/common/modules/PageInfoBar.vue';
import Button from '@/components/common/elements/Button.vue';
import TransitionDialog from '@/components/common/modules/TransitionDialog.vue';
import Dialog from '@/components/common/modules/Dialog.vue';

import SearchPanel from '@/components/setting/list/SearchPanel.vue';
import SearchList from '@/components/setting/list/SearchList.vue';

interface Buttons {
  id: number;
  type: string;
  color: string;
  name: string;
  value: string;
  route?: string;
}
@Component({
  components: {
    Header,
    SideMenu,
    PageInfoBar,
    SearchPanel,
    SearchList,
    Button,
    TransitionDialog,
    Dialog
  }
})
export default class SettingEmployeeList extends Vue {
  private display = EmployeeListDefine.display;
  private editPage = EmployeeListDefine.editPage;

  private setting = new Setting();

  // DB情報
  private projectId = hexabaseState.applicationId;
  private mainDsId = hexabaseState.datastoreIds['社員マスタ'];

  // マスタデータ・Hexaユーザー情報格納プロパティ
  private masterData: { [k: string]: { [k: string]: Array<{ [k: string]: string }> } } = {};
  private membersData: MemberList[] = [];

  // 検索フィールド情報格納プロパティ
  private searchFieldsData: Array<FieldChildrenProp> = [];
  private searchFieldCols = EmployeeListDefine.searchFieldCols;

  // アイテム取得時Param
  /* eslint-disable @typescript-eslint/camelcase */
  private payload = {
    conditions: [] as Array<{ [key: string]: string | string[] | boolean }>,
    per_page: 20,
    page: 1,
    use_display_id: true,
    include_links: true,
    include_lookups: true,
    omit_total_items: true,
    data_result_timeout_sec: 15
  };
  private totalItemPayload = {
    conditions: [] as Array<{ [key: string]: string | string[] | boolean }>,
    per_page: 0,
    page: 1,
    omit_fields_data: true,
    return_count_only: true,
    total_count_timeout_sec: 15
  };

  // ページネーション情報
  private page = {
    currentPage: 1,
    totalPage: 1,
    totalItem: 0,
    visible: 8,
    perPage: 20
  };

  // 一覧表示カラム
  private colums = EmployeeListDefine.tableColumns;

  // 一覧テーブル内でプルダウン入力させるカラム
  private selectItems = EmployeeListDefine.tableSelect;

  // 一覧テーブル内でチェックBOX入力させるカラム
  private checkItems: { [k: string]: string | boolean }[] = EmployeeListDefine.tableCheck;

  // API問い合わせ情報プロパティ
  private apiResponseData: GetItemsResponse = { items: [], totalItems: 0 };
  private apiResult = false;

  // 検索文言
  private dataTableText = EmployeeListDefine.message.dataTableText;
  private paginationText = '';
  private displayDesc = EmployeeListDefine.message.pageDescription;

  // 更新直前エラー発生時ダイアログ設定
  private dialogDefine = EmployeeListDefine.dialog;
  private isDialog = false;
  private dialogBodyText = '';
  private dialogName = '';
  private dialogTitle = EmployeeListDefine.dialog.errDialogTitle;
  private isCancelBtn = false;

  // アイテム登録後ダイアログ
  private dialogStatus = false;
  private isLoading = false;
  private editText = '';
  private buttons: Array<Buttons> = [
    {
      id: 1,
      color: 'primary',
      type: 'reload',
      name: 'reload',
      value: '閉じる'
    }
  ];

  async created() {
    this.initial();
  }

  /**
   * 初期画面生成
   */
  public async initial() {
    try {
      this.$store.commit('hexabase/setIsLoading', true);
      // モデル経由でマスタ情報取得
      await this.setting.loadMasterData();
      this.masterData = this.setting.getMaster();

      const [fieldsData, membersData, statusList] = await Promise.all([
        this.setting.getFieldsData(this.projectId, this.mainDsId),
        this.setting.getDbMembers(),
        this.setting.getStatusList(this.projectId, this.mainDsId)
      ]);

      const searchFieldsData = this.setting.createSearchConf(
        fieldsData,
        this.masterData,
        searchFormEmployeeList
      );

      // 削除済をステータスの選択肢に含めない
      const filterStatusList = statusList[0].statuses!.filter(item => {
        if ('削除済' !== item.status_name) {
          return item;
        }
      });
      this.setting.setStatusBySelectProp(searchFieldsData, filterStatusList!);

      // 環境ユーザープルダウンにヘキサユーザー情報設定
      this.selectItems[0].options = membersData.map(user => {
        return { text: `${user.email} (${user.username})`, value: user.u_id };
      });

      this.searchFieldsData.push(...searchFieldsData);
      this.membersData.push(...membersData);

      // 初期表示データ取得・作成
      // 検索Stateが存在していたら検索項目に値をセットする
      const stateCondition =
        searchState.searchCondition !== undefined ? searchState.searchCondition : [];
      for (const key in stateCondition) {
        const setConditionIndex = this.searchFieldsData.findIndex(
          field => field.display_id === stateCondition[key].id
        );
        if (setConditionIndex >= 0) {
          const dataType = this.searchFieldsData[setConditionIndex].dataType;
          switch (dataType) {
            case 'dslookup':
              this.searchFieldsData[setConditionIndex].props!.value = {
                item_id: stateCondition[key].search_value[0]
              };
              break;
            case 'status':
              this.searchFieldsData[setConditionIndex].props!.value =
                stateCondition[key].search_value[0];
              break;
            case 'datetime':
              this.searchFieldsData[setConditionIndex].props!.valueFrom =
                stateCondition[key].search_value[0];
              this.searchFieldsData[setConditionIndex].props!.valueTo =
                stateCondition[key].search_value[1];
              break;
            default:
              this.searchFieldsData[setConditionIndex].props!.value =
                stateCondition[key].search_value[0];
              break;
          }
        }
      }

      // 検索条件を与えられている場合、検索結果を取得
      if ('searchConditions' in this.$route.query) {
        await this.getItems(stateCondition);
      } else {
        this.apiResult = true;
      }

    } catch (e) {
      this.setError(e as string | object);
    } finally {
      this.$store.commit('hexabase/setIsLoading', false);
    }
  }

  /**
   * 一覧表示するアイテムを取得する
   * @param {array} condition - 検索条件を指定する
   * @param {number} page - 検索オフセット情報
   * @returns {void} 各内部プロパティに情報セット
   */
  async getItems(condition?: Array<{ [key: string]: string[] }>, page?: number) {
    if (condition !== undefined) {
      for (const key in searchState.searchCondition) {
        const findData = condition.find(
          recode => recode.id === searchState.searchCondition![key].id
        );
        if (!findData) {
          condition.push(searchState.searchCondition[key]);
        } else {
          const setConditionIndex = this.searchFieldsData.findIndex(
            field => field.display_id === searchState.searchCondition[key].id
          );
          if (setConditionIndex >= 0) {
            const componentType = this.searchFieldsData[setConditionIndex].component;
            switch (componentType) {
              case 'DatePickerRangeArea':
                if (searchState.searchCondition[key].search_value[0] && !findData.search_value[0]) {
                  findData.search_value[0] = searchState.searchCondition[key].search_value[0];
                }
                if (searchState.searchCondition[key].search_value[1] && !findData.search_value[1]) {
                  findData.search_value[1] = searchState.searchCondition[key].search_value[1];
                }
                break;
              default:
                break;
            }
          }
        }
      }
      searchState.setConditions(condition);
    }
    this.apiResult = false;
    if (condition !== undefined) {
      // conditionsに固定条件を付与するためconcatで参照渡しを回避する
      this.payload['conditions'] = condition.concat();
      this.totalItemPayload['conditions'] = condition.concat();
    }

    this.payload['page'] = page !== undefined ? page : 1;

    try {
      // ローディングをセット
      this.$store.commit('hexabase/setIsLoading', true);
      Promise.all([this.getTotalItems(), this.getFieldValue()]);
    } catch (errorMessage) {
      this.setError(errorMessage as string | object);
    } finally {
      this.apiResult = true;
    }
  }

  private async getFieldValue() {
    try {
      // アイテムのStatusが削除済(employee_deleted)は検索対象に含めない条件を追加
      this.payload.conditions.push(EmployeeMasterDeleteStatusExcludeSearch);
      this.apiResponseData = await this.setting.getItemSearch(
        this.projectId,
        this.mainDsId,
        this.payload
      );
      if (this.apiResponseData.errors !== undefined) {
        this.dataTableText = this.setting.getErrMessage('ITEMLIST', this.apiResponseData);
      } else if (this.apiResponseData.items.length === 0) {
        this.dataTableText = this.setting.getMessage('ITEMLIST', 'NOT_FOUND');
      }

      for (const index in this.apiResponseData.items) {
        const item = this.apiResponseData.items[index];
        item.account_activation_date = this.setting.editDatetype(
          item.account_activation_date,
          'Asia/Tokyo',
          'yyyy/MM/dd'
        );
        item.account_invalidation_date = this.setting.editDatetype(
          item.account_invalidation_date,
          'Asia/Tokyo',
          'yyyy/MM/dd'
        );
      }
    } catch (error) {
      this.dataTableText =
        typeof error === 'string' ? error : this.setting.getDefaultErrorMessage();
    } finally {
      this.$store.commit('hexabase/setIsLoading', false);
    }
  }

  private async getTotalItems() {
    this.page.totalItem = -1;
    this.paginationText = '';
    // アイテムのStatusが削除済(employee_deleted)は検索対象に含めない条件を追加
    this.totalItemPayload.conditions.push(EmployeeMasterDeleteStatusExcludeSearch);
    try {
      const result = await this.setting.getItemSearch(
        this.projectId,
        this.mainDsId,
        this.totalItemPayload
      );
      if (result.totalItems === -1) {
        this.paginationText =
          '件数の取得がタイムアウトしました。条件を絞り込んで検索してください。';
        this.page.totalItem = 0;
      } else {
        this.apiResponseData.totalItems = result.totalItems;
        // アイテム数等をセット
        (this.page.totalItem = this.apiResponseData.totalItems), 20;
        this.page.totalPage = this.setting.getTotalPage(this.apiResponseData.totalItems, 20);
        this.page.currentPage = this.payload['page'];
      }
    } catch (error) {
      this.paginationText =
        typeof error === 'string' ? error : this.setting.getDefaultErrorMessage();
      this.page.totalItem = 0;
    }
  }

  /**
   * 行を選択した際のイベントハンドリング
   * @param rowData 選択肢た行のデータ
   */
  onClickItemRow(rowData: any) {
    this.$router.push({ path: `/${EmployeeListDefine.editPagePath}/${rowData.i_id}`, query: { searchConditions: 'true' }});
  }

  /**
   * 検索パネルにて「リセット」ボタン押下時
   * 内部プロパティの検索情報を消去する
   * @returns void 内部プロパティ消去
   */
  private resetSearchForm(): void {
    this.payload.conditions.splice(0);
    this.totalItemPayload.conditions.splice(0);
    this.payload.page = 1;
  }

  /**
   * 確認ダイアログのボタン押下後処理
   * @param event {{dialog: boolean, isAgree: boolean, name: string}} - 押下情報
   * @returns void
   */
  private confirmOpratipon(event: { dialog: boolean; isAgree: boolean; name: string }): void {
    this.isDialog = false;
  }

  /**
   * 汎用ダイアログの設定を行います
   */
  private setDialog(name: string, text: string, isCancel: boolean, isDialog: boolean): void {
    this.dialogName = name;
    this.dialogBodyText = text;
    this.isCancelBtn = isCancel;
    this.isDialog = isDialog;
  }

  /**
   * APIエラー・プログラムエラーの場合共通エラーダイアログ表示
   * @param errorMessage {string | object}
   */
  private setError(errorMessage: string | object): void {
    if (typeof errorMessage === 'object') errorMessage = this.setting.getDefaultErrorMessage();
    // エラーをキャッチした際共通エラーダイアログにセット
    this.$store.commit('hexabase/setIsError', true);
    this.$store.commit('hexabase/setErrorMessage', errorMessage);
  }

  /**
   * ページネーションボタンが押下された際
   * 指定ページの情報を問い合わせに行く
   * @param {number} response - 子コンポーネントから渡ってきたページ番号
   * @returns void API問い合わせ
   */
  private pageNaviAction(response: number) {
    this.selectItems = [];
    this.getItems(undefined, response);
  }
}


















































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import Button from '@/components/common/elements/Button.vue';
import SingleText from '@/components/common/elements/SingleText.vue';
import Pagination from '@/components/common/modules/Pagination.vue';
import CheckBoxTable from '@/components/common/modules/CheckBoxTable.vue';
import { Items } from '@/services/hexabase/types';
import Icon from '@/components/common/elements/Icon.vue';

@Component({
  components: { Button, SingleText, Pagination, CheckBoxTable, Icon }
})
export default class SearchList extends Vue {
  // ページネーション情報
  @Prop({
    default: () => ({
      currentPage: 1,
      totalPage: 1,
      visible: 1
    })
  })
  private pageInfo?: { [k: string]: number };
  // 選択アイテム
  private selectedItem: Array<Items> = [];
  // 一覧内のチップ背景色 (色、カラム名)
  private orderStatusId = 'status';
  private chipItems = [{ color: '', name: this.orderStatusId, class: 'status-tag--order' }];
  // 一覧内で遷移ボタンを表示するColumn
  private buttonItems = [
    { name: 'i_id', text: '詳細', alwaysDisplayed: true, class: 'btn-normal' }
  ];

  // データテーブルに表示するColumnリスト
  @Prop({ default: () => [] })
  private colums?: [];
  // データテーブルに表示するデータリスト
  @Prop({ default: () => [] })
  private data?: [];
  // ステータス更新動作キャッチ用フラグ
  @Prop({ default: false })
  private flush!: boolean;
  // api結果フラグ
  @Prop({ default: false })
  private apiResult!: boolean;
  // テーブル内テキスト
  @Prop({ default: '' })
  private tableText!: string;
  // ページングテキスト
  @Prop({ default: '' })
  private paginationText!: string;
  // 詳細画面ルーティング名
  @Prop({ default: '' })
  private detailRoute!: string;

  @Prop({ default: () => [] })
  private selectItems?: Array<{ [k: string]: string | { [k: string]: string }[] }>;
  @Prop({ default: () => [] })
  private autocompleteItems?: Array<{ [k: string]: string | { [k: string]: string }[] }>;
  @Prop({ default: () => [] })
  private checkItems?: Array<{ [k: string]: string }>;

  // 変更を感知したら内部選択プロパティを初期化
  // 選択済みアイテムを消去
  @Watch('flush')
  watchFlush(): void {
    if (this.flush) this.selectedItem.splice(0);
  }

  /**
   * 選択された情報を内部プロパティに格納
   * @param {any} response - 子コンポーネントより渡される選択アイテムリスト
   * @return {void} - 選択状態を格納
   */
  @Emit('selectItemHandle')
  private selectItemHandle(response: any) {
    this.selectedItem.splice(0);
    this.selectedItem.push(...response);
    return this.selectedItem;
  }

  /**
   * ページネーションの押下ページ番号をEmit
   * @param {any} response - 子コンポーネントより渡される値
   * @return {void} Emit
   */
  @Emit('pagenaviAction')
  private pagenaviAction(response: any) {
    return response;
  }

  /**
   * 明細詳細ボタン押下時の画面遷移
   * @param {any} response - 子コンポーネントより渡される値
   * @returns {void} - ルーティング
   */
  private toDetailPage(response: any) {
    this.$router.push({
      name: this.detailRoute,
      params: { id: response.record.i_id },
      query: { searchConditions: 'true' }
    });
  }

  /**
   * ページネーション部分に表示する文言を生成します
   * @param {{[k: string]: number}} pageinfo - ページ情報
   * @param {number} dataCount - 既存アイテム数
   */
  private createPageNation(pageInfo: { [k: string]: number }, dataCount: number): string | null {
    if (this.paginationText !== '') return this.paginationText;
    if (pageInfo.totalItem === 0) return null;
    if (pageInfo.totalItem === -1) return '全件数を取得中...';
    const initialNum = 1;
    const start =
      pageInfo.currentPage > initialNum
        ? (pageInfo.currentPage - initialNum) * pageInfo.perPage + initialNum
        : initialNum;
    const end = start - 1 + dataCount;
    return `全${pageInfo.totalItem}件中 ${start}件〜${end}件を表⽰`;
  }

  /**
   * 押下した行のアイテムIDを返却
   * @return {void} - Emit セレクトアイテムデータ
   */
  @Emit('clickRowOperation')
  clickRowOperation(data: any): Array<{ [k: string]: string }> {
    return data;
  }

  @Emit('onCheck')
  onCheck(item: any) {
    return item;
  }

  @Emit('changeSelect')
  changeSelect(data: any) {
    return data;
  }
}
